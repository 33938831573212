import PathPage, {
  getServerSideProps as catchAllGetServerSideProps,
} from '@orgnc/core/lib/wordpress/pages/[[...path]].page';
import { GetServerSideProps } from 'next';

import { getTheme } from '../../../theme';
import { getDomainThemeId } from '../../../themes';
import { parseCanonicalDomain } from '../../../utils/useCanonicalDomain';

export const getServerSideProps: GetServerSideProps = async (props) => {
  return catchAllGetServerSideProps({
    ...props,
    theme: getTheme(getDomainThemeId(parseCanonicalDomain(props.resolvedUrl))),
  });
};

export default PathPage;
